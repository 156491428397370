import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import parse from "html-react-parser";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Link } from "gatsby";
import { Button } from "react-bootstrap";

const PolicyTemplate = ({ data }) => {
  const { wpPolicy } = data;
  const { siteUrl } = data.site.siteMetadata;
  const { privacyFields } = wpPolicy;

  return (
    <Layout>
      <GatsbySeo
        title={wpPolicy.title}
        description={wpPolicy.title}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}${wpPolicy.uri}`,
          title: `${wpPolicy.title}`,
          description: `${wpPolicy.title}`,
        }}
      />

      <Container className="my-5 my-md-6 my-xl-8">
        <Row>
          <Col>
            <h1 className="text-center pb-4 pb-md-5 intro-heading">{wpPolicy.title}</h1>
          </Col>
        </Row>
        {wpPolicy.content && (
          <Row>
            <Col>
              <div className="policy-content">
                {parse(wpPolicy.content)}
              </div>
            </Col>
          </Row>
        )}
        <div className="d-flex flex-column flex-md-row justify-content-center mt-5">
          {privacyFields.link && privacyFields.link.url && (
            <Button
              className="px-4 py-3 mt-md-5 mb-2 me-md-3 w-fit daxPro-semibold"
              as={Link}
              to={privacyFields.link?.url}
              variant="secondary"
              target={privacyFields.link?.target ?? "_blank"}
            >
              {privacyFields.link?.title}
            </Button>
          )}
          {privacyFields.link2 && privacyFields.link2.url && (
            <Button
              className="px-4 py-3 mt-2 mt-md-5 mb-2 me-md-3 w-fit daxPro-semibold"
              as={Link}
              to={privacyFields.link2.url }
              variant="secondary"
              target={privacyFields.link2?.target ?? "_blank"}
            >
              {privacyFields.link2.title}
            </Button>
          )}
          {privacyFields.link3 && privacyFields.link3.url && (
            <Button
              className="px-4 py-3 mt-2 mt-md-5 mb-2 w-fit daxPro-semibold"
              as={Link}
              to={privacyFields.link3?.url}
              variant="secondary"
              target={privacyFields.link3?.target ?? "_blank"}
            >
              {privacyFields.link3?.title}
            </Button>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default PolicyTemplate;

export const blogData = graphql`
  query ($id: String!) {
    wpPolicy(id: { eq: $id }) {
      title
      content
      uri
      privacyFields {
        link {
          target
          title
          url
        }
        link2 {
          target
          title
          url
        }
        link3 {
          target
          title
          url
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
